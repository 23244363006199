export default {
    font: {
        fontName: "Palanquin",
        fontFamily: ["Palanquin", "sans-serif"],
        fontSize: "18px",
        lineHeight: "1.666"
    },
    palette: {
        "primary-050": "#E0FCFF",
        "primary-100": "hsl(185, 100%, 90%)",
        "primary-200": "hsl(185, 76%, 75%)",
        "primary-300": "#54D1DB",
        "primary-400": "#38BEC9",
        "primary-500": "hsl(185, 96%, 35%)",
        "primary-600": "#14919B",
        "primary-700": "#0E7C86",
        "primary-800": "#0A6C74",
        "primary-900": "#044E54",

        // Neutrals
        "neutral-050": "#F0F4F8",
        "neutral-100": "#D9E2EC",
        "neutral-200": "#BCCCDC",
        "neutral-300": "#9FB3C8",
        "neutral-400": "#829AB1",
        "neutral-500": "#627D98",
        "neutral-600": "#486581",
        "neutral-700": "#334E68",
        "neutral-800": "#243B53",
        "neutral-900": "#102A43",

        // Supporting
        "indigo-050": "#E0E8F9",
        "indigo-100": "#BED0F7",
        "indigo-200": "#98AEEB",
        "indigo-300": "#7B93DB",
        "indigo-400": "#647ACB",
        "indigo-500": "#4C63B6",
        "indigo-600": "#4055A8",
        "indigo-700": "#35469C",
        "indigo-800": "#2D3A8C",
        "indigo-900": "#19216C",

        "pink-050": "#FFE0F0",
        "pink-100": "#FAB8D9",
        "pink-200": "#F191C1",
        "pink-300": "#E668A7",
        "pink-400": "#DA4A91",
        "pink-500": "#C42D78",
        "pink-600": "#AD2167",
        "pink-700": "#9B1B5A",
        "pink-800": "#781244",
        "pink-900": "#5C0B33",

        "red-050": "#FFEEEE",
        "red-100": "#FACDCD",
        "red-200": "#F29B9B",
        "red-300": "#E66A6A",
        "red-400": "#D64545",
        "red-500": "#BA2525",
        "red-600": "#A61B1B",
        "red-700": "#911111",
        "red-800": "#780A0A",
        "red-900": "#610404",

        "yellow-050": "#FFFAEB",
        "yellow-100": "#FCEFC7",
        "yellow-200": "#F8E3A3",
        "yellow-300": "#F9DA8B",
        "yellow-400": "#F7D070",
        "yellow-500": "#E9B949",
        "yellow-600": "#C99A2E",
        "yellow-700": "#A27C1A",
        "yellow-800": "#7C5E10",
        "yellow-900": "#513C06"
    },
    fontSize: {
        12: "12px",
        14: "14px",
        16: "16px",
        18: "18px",
        20: "20px",
        24: "24px",
        32: "32px",
        48: "48px"
    },
    spacing: {
        4: "4px",
        8: "8px",
        12: "12px",
        16: "16px",
        24: "24px",
        32: "32px",
        48: "48px",
        64: "64px",
        96: "96px",
        128: "128px",
        192: "192px",
        256: "256px",
        384: "384px",
        512: "512px",
        640: "640px",
        768: "768px"
    },
    borderRadius: {
        radius1: "4px",
        radius2: "6px",
        radius3: "8px",
        radius4: "12px",
        radius5: "16px",
        radius6: "24px",
        radius7: "32px",
    }
};
