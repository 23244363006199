// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blogg-index-js": () => import("./../../../src/pages/blogg/index.js" /* webpackChunkName: "component---src-pages-blogg-index-js" */),
  "component---src-templates-apper-page-js": () => import("./../../../src/templates/apper-page.js" /* webpackChunkName: "component---src-templates-apper-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-extra-page-js": () => import("./../../../src/templates/extra-page.js" /* webpackChunkName: "component---src-templates-extra-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-integrasjon-page-js": () => import("./../../../src/templates/integrasjon-page.js" /* webpackChunkName: "component---src-templates-integrasjon-page-js" */)
}

